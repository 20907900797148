import React, { ReactNode, ElementType } from 'react'
import classNames from 'classnames'
import { get, size } from 'lodash'
import { useMenu } from 'Components/layout/Menu'
import { withNewLines } from 'Utils/api'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import { DynamicSectionsDataType } from 'Components/sections/DynamicSections'
import Touchable from 'Components/touchables/Touchable'
import Layout from 'Components/layout/Layout'
import Text from 'Components/texts/Text'
import Row from 'Components/Row/Row'
import { fade, fadeUp } from 'Utils/animations'
import { ApiSeo } from 'Typings/api'
import SEO from 'Components/SEO'
import { FooterProps } from 'Components/layout/Footer'
import styles from './AltPageLayout.module.scss'

export type AltPageLayoutNavs = ('services' | 'portfolio' | 'articles')[]

export type AltPageLayoutProps = {
  className?: string
  headerClassName?: string
  seo: ApiSeo
  path: string
  title?: string
  subTitle?: string
  navs?: AltPageLayoutNavs
  children: ReactNode
  footerProps?: FooterProps
  firstSectionHasLabel?: boolean
  hasHeaderIndent?: boolean
  HeaderComponent?: ElementType
  overflow?: boolean
  isArticle?: boolean
}

export const isFirstSectionHasLabel = (sections: DynamicSectionsDataType[]) => {
  return Boolean(size(sections) > 0 && get(sections[0], 'label'))
}

const WITH_MENU_OPEN = ['services']

const AltPageLayout = (props: AltPageLayoutProps) => {
  const {
    className,
    headerClassName,
    seo,
    path,
    title,
    subTitle,
    navs,
    children,
    footerProps,
    firstSectionHasLabel = false,
    hasHeaderIndent = true,
    HeaderComponent,
    overflow = true,
    isArticle = false,
  } = props

  const _navs = ['/', ...(navs || [])]

  const { setMenuState } = useMenu()

  const hasSubTitle = Boolean(subTitle)
  const hasBottomIndent =
    hasHeaderIndent && ((hasSubTitle && firstSectionHasLabel) || !hasSubTitle)

  const { t } = useTranslation()

  return (
    <Layout
      className={classNames([
        styles.root,
        className,
        { [styles.hasBottomIndent]: hasBottomIndent },
      ])}
      headerTheme="alt"
      footerProps={footerProps}
      overflow={overflow}
    >
      <SEO article={isArticle} data={seo} path={path} />
      <BaseContainer
        className={classNames([styles.header, headerClassName])}
        contentClassName={styles.headerContent}
        vertical="none"
      >
        <Row className={styles.navs} {...fade()}>
          {_navs.map((i) => {
            const withMenuOpen = WITH_MENU_OPEN.includes(i)
            return (
              <Text key={i} className={styles.nav} variant="sm-bold">
                <Row>
                  <Touchable
                    styling="alt"
                    link={withMenuOpen ? '' : i === '/' ? '/' : `/${i}`}
                    onClick={withMenuOpen ? () => setMenuState(i) : undefined}
                  >
                    {t(`navs.${i}`)}
                  </Touchable>
                  <span>{'/'}</span>
                </Row>
              </Text>
            )
          })}
        </Row>
        {Boolean(title) && (
          <Text
            Component={HeaderComponent}
            className={styles.title}
            variant="heading"
            {...fadeUp({ index: 1 })}
          >
            {withNewLines(title!)}
          </Text>
        )}
        {Boolean(subTitle) && (
          <Text className={styles.subTitle} variant="sm" {...fadeUp({ index: 2 })}>
            {withNewLines(subTitle!)}
          </Text>
        )}
      </BaseContainer>
      {children}
    </Layout>
  )
}

export default AltPageLayout
